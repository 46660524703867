import React, {  } from 'react';
import SiteLayoutEmbeded from '../../components/SiteLayoutEmbeded';
import { Location, Link } from '@reach/router';
import { FeilComponent } from '../feil';
import { Button } from 'antd';

// @ts-ignore
import {backbutton} from './embeded.module.scss';

function Index() {
    return (
        <Location>
            {({ location }) => (
                <SiteLayoutEmbeded>
                    <FeilComponent location={location} />
                    <Link to="/embeded/"><Button icon="arrow-left" className={backbutton}>Tilbake</Button></Link>
                </SiteLayoutEmbeded>
            )}
        </Location>
    );
}

export default Index;
