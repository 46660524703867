import React, { useEffect, useState } from 'react';
import SiteLayout from '../components/SiteLayout';
import { Location, navigate } from '@reach/router';
import { Button, Form, Input } from 'antd';
import qs from 'query-string';

// @ts-ignore
import Oida from '../../static/oida.inline.svg';

// @ts-ignore
import { info, image, backbutton } from './oida.module.scss';

import { Link } from 'gatsby';
import { useTrackedSessionStoreStore } from "../state/sessionStore";
import { ArrowLeftOutlined } from "@ant-design/icons";

export function FeilComponent({ location }) {

    const sessionStore = useTrackedSessionStoreStore();

    useEffect(() => {
        doCheck();
    }, []);

    async function doCheck() {
        const parsed = qs.parse(location.search);
        const result = await sessionStore.check({ sessionId: parsed.sid as string });
    }

    return (
        <>
            <p style={{ fontWeight: 400 }}>Oida..</p>
            <br />
            <p className={info}>
                Oida. Her gikk noe feil. Prøv på nytt.
            </p>
        </>
    );
}

function Feil() {
    return (<Location>
        {({ location, navigate }) => (
            <SiteLayout location={location}>
                <Oida className={image} />
                <br />
                <h1>Aktiver HAN</h1>
                <FeilComponent location={location} />
                <Link to="/"><Button icon={<ArrowLeftOutlined />} className={backbutton}>Prøv på nytt</Button></Link>
            </SiteLayout>
        )}
    </Location>);
}

export default Feil;
